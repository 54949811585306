var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑API信息"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"接口名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入接口名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入接口名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"接口类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['api_type',{
            rules: [
              { required: true, message: '请选择接口类型' } ]
          }]),expression:"['api_type',{\n            rules: [\n              { required: true, message: '请选择接口类型' },\n            ]\n          }]"}]},[_c('a-radio',{attrs:{"value":"query"}},[_vm._v(" 查询 ")]),_c('a-radio',{attrs:{"value":"operation"}},[_vm._v(" 操作 ")])],1)],1),_c('a-form-item',{attrs:{"label":"默认开通"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_default',{
            rules: [
              { required: true, message: '请选择是否默认开通' } ]
          }]),expression:"['is_default',{\n            rules: [\n              { required: true, message: '请选择是否默认开通' },\n            ]\n          }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" 否 ")])],1)],1),_c('a-form-item',{attrs:{"label":"说明"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description', {
            rules: [
              { max: 200, message: '最多200个字符' }
            ]
          }]),expression:"['description', {\n            rules: [\n              { max: 200, message: '最多200个字符' }\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }