<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑API信息"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="接口名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入接口名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="接口类型">
          <a-radio-group
            v-decorator="['api_type',{
              rules: [
                { required: true, message: '请选择接口类型' },
              ]
            }]"
          >
            <a-radio value="query">
              查询
            </a-radio>
            <a-radio value="operation">
              操作
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="默认开通">
          <a-radio-group
            v-decorator="['is_default',{
              rules: [
                { required: true, message: '请选择是否默认开通' },
              ]
            }]"
          >
            <a-radio :value="true">
              是
            </a-radio>
            <a-radio :value="false">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="说明">
          <a-textarea
            v-decorator="['description', {
              rules: [
                { max: 200, message: '最多200个字符' }
              ]
            }]"
          />
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findApiInterfaceForm, updateApiInterface } from '@/api/api_interface'

export default {
  name: 'EditApiInterface',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'api_interface' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findApiInterfaceForm(this.id).then((res) => {
        this.form.setFieldsValue(res.data)
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateApiInterface(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
